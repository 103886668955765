import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';
import CrudProductAttributeItem from '../components/CrudProductAttributeItem';

export function getFormDefinition(vue) {
  return {
    type: 'tab',
    saveButtons: [true, true], // Page, tab 1, tab 2, ..
    showFormLabels: [true, true], // Tab 1, tab 2, ....
    labels: ['common.productAttribute', 'common.productAttributeItem'],
    headerTemplate: (str, form) => `
    <h3>
    ${form.name || ''}
    </h3>
    `,
    groups: [
      {
        // tab 1
        name: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 64,
            required: true
          },
          rules: [{ validator: validatorRequire }]
        }
      },
      // tab 2
      {
        productAttributeItems: {
          type: CrudProductAttributeItem,
          props: {
            openInFullscreen: false,
            parentID: 'productAttributeID',
            parentIdFilterField: 'productAttributeID',
            permissionCode: 'ProductAttributeItem'
          }
        }
      }
    ]
  };
}

const layoutTabGeneral = {
  named: true,
  sections: [
    {
      width: 100,
      fields: ['name']
    }
  ]
};

const layoutProductAttributes = {
  sections: [
    {
      width: 100
    }
  ]
};

export function getCreateLayoutDefinition(vue) {
  return {
    groups: [layoutTabGeneral]
  };
}

export function getEditLayoutDefinition(vue) {
  return {
    groups: [layoutTabGeneral, layoutProductAttributes]
  };
}

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return item;
}
